.form-container {
    border: none;

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 0;
        padding: 1.5rem 2rem 0.5rem;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: bold;
        font-family: $font-primary;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
    }

    label {
        color: $text-color-1;
        font-family: $font-primary;
        font-size: 18px;
        font-weight: 600;
    }
}

input::-ms-clear,
input::-ms-reveal {
    padding: 0.125rem;
}

.login-form {
    input {
        color: $primary-color;
        height: initial;
        padding: 1.2rem;
        border: none;
        border-radius: 0;
        background-color: $form-background-2 !important;
        outline: none !important;
        font-weight: normal;

        &[disabled] {
            cursor: default;
        }

        &:focus {
            color: $primary-color !important;
            font-weight: bold;
        }

        &.is-invalid {
            border: solid $red 1px;
        }
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray-700;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $gray-700;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $gray-700;
    }
}

.filter-form {
    .filters {
        .filter {
            display: flex;
            justify-content: flex-start;
            align-content: center;
            align-items: center;

            margin: 0 3rem 0 0;
        }
    }

    label {
        margin-right: 1rem;
        color: $text-color-1;
        font-family: $font-primary;
        font-size: 0.9rem;
        font-weight: 600;
    }

    select {
        width: fit-content;
        height: initial;
        border: none;
        border-radius: 0;
        background-color: $form-background-2 !important;
        outline: none !important;
        font-family: $font-secondary;
        font-weight: normal;

        &[disabled] {
            cursor: default;
        }

        &:focus {
            font-weight: bold;
        }

        &.is-invalid {
            border: solid $red 1px;
        }
    }
}

.form-custom {
    input,
    select,
    textarea {
        color: $primary-color;
        height: initial;
        padding: 1.2rem;
        background-color: $form-background-2 !important;
        outline: none !important;
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: normal;

        &[disabled] {
            cursor: default;
        }

        &:focus {
            color: $primary-color !important;
            font-weight: bold;
        }

        &.is-invalid {
            border: solid $red 1px;
        }
    }

    .form-group-info {
        width: 100%;
        display: block;

        font-family: $font-secondary;
        font-size: 14px;
        font-weight: normal;
    }
}

.bootstrap-select {
    margin-bottom: 2.5rem !important;

    button {
        width: fit-content;
        height: initial;
        padding: 1.375rem;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        background-color: $form-background-2 !important;
        outline: none !important;
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 14px;

        &::after {
            content: none;
        }

        &[disabled] {
            cursor: default;
        }

        &:focus {
            color: $primary-color !important;
            font-weight: bold;
        }

        &.is-invalid {
            border: solid $red 1px;
        }

        span {
            color: $primary-color;
        }
    }

    .dropdown-menu {
        li {
            font-size: 14px;
        }
    }

    .dropdown-toggle:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0 !important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
}

.increment-image,
.cloned-image {
    .input-group-btn {
        position: absolute;
        right: 10px;
        z-index: 9999;

        button {
            width: 30px;
            height: 30px;
            border: none;
            border-radius: 0;
        }
    }
}

.images-container {
    display: flex;
    flex-wrap: wrap;

    .image-container {
        position: relative;

        display: flex;
        margin: 10px;
        background-color: aliceblue;
        padding: 5px;

        img {
            max-width: 85px;
            max-height: 75px;

            .banner {
                max-width: 100%;
            }
        }

        video {
            max-width: 125px;
            max-height: 150px;
        }

        .input-group-btn {
            display: flex;
            align-items: center;

            position: absolute;
            top: 0px;
            right: 15%;

            button,
            a {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 30px;
                height: 30px;
                border: none;
                border-radius: 2px;

                font-size: 12px;

                margin: 0 5px 0 0;
            }
        }

        .position-image {
            margin-right: 10px;

            display: flex;
            display: flex;
            align-items: center;

            .position-image-text {
                margin-right: 5px;
            }

            .position-image-controls {
                display: flex;
                justify-content: center;
                flex-flow: column wrap;
                align-self: center;

                height: 75px;

                .control {
                    margin: 1px 0;
                    font-size: 14px;
                    border-radius: 0;
                }
            }
        }
    }
}

.resize-image-button {
    font-size: 14px;
}
