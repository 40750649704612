.card {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box;
    border: none !important;
    border-radius: 0 !important;

    box-shadow: 0px 2px 24px -12px rgba(0,0,0,0.3);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 2rem;
    padding: 1rem 2rem;

    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    font-family: $font-primary;
    text-transform: uppercase;

    background-color: transparent;
    border: none;

    .card-header-item {
        font-size: 1rem;
        text-decoration: none;
    }

    a:hover {
        color: $primary-color;
    }
}

.card-footer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: -20px;
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}