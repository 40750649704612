.custom-button {
    display: block;
    position: relative;
    width: fit-content;
    height: initial;
    margin: 0 auto;
    padding: 1.2rem 5rem;
    font-family: $font-primary;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    
    border: solid $gray-500;
    border-radius: 0;

    color: $white;
    background-color: $gray-500;

    -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out,background-color .2s ease-in-out;
    -o-transition: color .2s ease-in-out,background-color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out,background-color .2s ease-in-out;
    transition: color .2s ease-in-out,background-color .2s ease-in-out;  
    
    &.primary-button {
        color: $primary-color;
        background-color: $white;
        border: solid $primary-color;
        
        &:hover {
            color: $white;
            background-color: $primary-color;
        }
    }

    &.secondary-button {
        color: $white;
        background-color: $primary-color;
        border: solid $primary-color;

        &:hover {
            color: $primary-color;
            background-color: $white;
        }
    }

    &.disable-button {
        color: $white;
        background-color: $gray-500;
        border: solid $gray-500;
    }
}
