.product-details-container {
    max-width: 100%;

    .brand-banner {
        width: 100%;
        margin: 0 auto;

        .banner {
            width: 100%;
        }
    }

    .product-details {
        .product-logo {
            width: 100%;
            margin: 0 auto;

            display: flex;
            justify-content: center;

            .logo {
                max-width: 300px;
            }
        }

        .product-name {
            color: $text-color-1;
            font-family: $font-secondary;
            font-size: 1.5rem;
            font-weight: 800;
            letter-spacing: 1px;
        }

        .product-info {
            color: $text-color-1;
            font-family: $font-primary;
            font-size: 1.1rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 0.4rem;

            .highlight {
                font-weight: 500;
                color: $primary-color;
            }
        }

        .product-description {
            color: $text-color-2;
            font-family: $font-primary;
            font-size: 1.1rem;

            white-space: pre-line;
        }

        .product-includes {
            color: $text-color-2;
            font-family: $font-primary;
            font-size: 1.1rem;

            white-space: pre-line;
        }

        .product-downloads {
            a {
                color: $text-color-2;
                font-family: $font-primary;
                font-size: 0.9rem;
                font-weight: 500;

                &:hover {
                    color: $primary-color;
                }
            }
        }

        .product-features {
            padding: 0 1rem 1rem;
            font-family: $font-primary;
            border-right: solid $gray-300 1px;

            p {
                padding: 0 1rem 1rem;
                white-space: pre-line;
            }
        }

        .product-specs {
            padding: 0 1rem 1rem;
            font-family: $font-primary;

            p {
                padding: 0 1rem 1rem;
                white-space: pre-line;
            }
        }

        h3 {
            padding: 0 1rem 1rem;
            font-size: 1rem;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}

#productImages {
    height: 375px;

    .product-images {
        display: flex;
        height: 375px;

        img {
            display: block;
            height: auto;
            max-width: 100%;
        }

        .img-display {
            flex-grow: 1;
            height: 375px;

            display: flex;
            justify-content: center;
        }

        .thumbnails {
            height: 375px;

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        .thumb {
            width: 85px;

            img,
            video {
                max-width: 85px;
                max-height: 125px;

                opacity: 0.7;

                -webkit-transition: opacity 0.3s ease-in-out;
                -moz-transition: opacity 0.3s ease-in-out;
                -o-transition: opacity 0.3s ease-in-out;
                -ms-transition: opacity 0.3s ease-in-out;
                transition: opacity 0.3s ease-in-out;
            }

            .video {
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 56px;
                    left: 30px;
                    width: 30px;
                    height: 30px;
                    background: url("/img/icons/icon-play-white.svg") no-repeat;
                }
            }
        }

        .thumb:hover,
        .thumb.thumb-active {
            img,
            video {
                opacity: 1;
            }
        }

        .zoom {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 275px;
            width: 100%;

            img {
                display: block;
                max-width: 300px;
                max-height: 450px;

                cursor: zoom-in;
                cursor: -moz-zoom-in;
                cursor: -webkit-zoom-in;
            }

            video {
                display: block;
                max-width: 100%;
                max-height: 375px;
            }
        }

        .modal {
            z-index: 9999;

            .modal-dialog {
                border: none;
                border-radius: 0;

                max-width: 80vw;
                max-height: 80vh;

                .modal-content {
                    border: none;
                    border-radius: 0;

                    .modal-header {
                        border: none;
                        border-radius: 0;

                        background-color: $primary-color;

                        button {
                            color: $white;
                            opacity: 0.75;

                            -webkit-transition: opacity 0.2s ease-in-out;
                            -moz-transition: opacity 0.2s ease-in-out;
                            -o-transition: opacity 0.2s ease-in-out;
                            -ms-transition: opacity 0.2s ease-in-out;
                            transition: opacity 0.2s ease-in-out;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    .modal-body {
                        display: flex;
                        justify-content: center;

                        img,
                        video {
                            max-width: 80vw;
                            max-height: 80vh;
                        }
                    }
                }
            }
        }
    }

    .zoomImg {
        background-color: $white;

        min-width: 600px !important;
        min-height: 900px !important;
    }

    .zoom-icon {
        position: absolute;
        bottom: 0;
        right: 0;

        color: $text-color-1;

        .zoom-button {
            color: $white;
            background-color: $primary-color;

            opacity: 0.75;

            -webkit-transition: opacity 0.2s ease-in-out;
            -moz-transition: opacity 0.2s ease-in-out;
            -o-transition: opacity 0.2s ease-in-out;
            -ms-transition: opacity 0.2s ease-in-out;
            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.carousel {
    width: 100%;
    height: 100%;

    .carousel-control {
        position: absolute;
        top: 0;
        left: 45px !important;
        width: 50px;
        height: 50px;

        font-size: 30px !important;
        font-weight: bold;
        color: $text-color-1;

        text-align: center;

        background-color: transparent;

        z-index: 9998;

        &.carousel-control-prev {
            top: -35px !important;
        }

        &.carousel-control-next {
            top: calc(100% - 10px) !important;
        }
    }

    .carousel-inner {
        position: relative;
        width: fit-content;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
}

@include media-breakpoint-up(sm) {
    .product-details-container {
        max-width: 80%;
    }
}
