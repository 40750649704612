#main-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    color: $white;
    background-color: $secondary-color;
    font-family: $font-primary;

    .container {
        position: relative;
        margin: auto;
    }

    #footer-rrss {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 3rem 0 1rem;
        
        
        img {
            margin: 0 1.2rem;

            opacity: 1;
    
            -webkit-transition: opacity .3s ease-in-out;
            -moz-transition: opacity .3s ease-in-out;
            -o-transition: opacity .3s ease-in-out;
            -ms-transition: opacity .3s ease-in-out;
            transition: opacity .3s ease-in-out;

            &:hover {
                opacity: .7;
            }
        }
    }

    #footer-bottom {
        padding: 0.5rem 0;
        background-color: $secondary-color;
        
        #footer-info {
            padding-bottom: 10px;
            
            p {
                color: $white;
                margin-bottom: 0;
            }
        }
    }
}

#sidebar-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: $white;
    background-color: $secondary-color;
    font-family: $font-primary;
}
