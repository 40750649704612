#main-header {
    border-top: solid 5px $primary-color;

    .container {
        max-width: 100%;
        padding: 0 45px;
    }

    .navbar {
        padding: 0;

        .navbar-brand {
            margin: 0 auto;
            padding: 5px;

            .nav-logo {
                height: 90px;
            }
        }

        .navbar-toggler {
            margin-left: 2rem;
            padding: 0;
            border: none;

            i {
                color: #595959;
                padding: 5px;
            }
        }
       
        .navbar-nav {
            padding: 1rem 2rem;

            .nav-item {
                width: 100%;
                margin: 0;
                padding: .25rem 0;
                border-bottom: solid 1px $gray-200;

                &:last-child {
                    border-bottom: none;
                }

                .nav-link {
                    color: $primary-color;
                    font-family: $font-primary;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    text-transform: capitalize;

                    -webkit-transition: opacity .3s ease-in-out,color .3s ease-in-out;
                    -moz-transition: opacity .3s ease-in-out,color .3s ease-in-out;
                    -o-transition: opacity .3s ease-in-out,color .3s ease-in-out;
                    -ms-transition: opacity .3s ease-in-out,color .3s ease-in-out;
                    transition: opacity .3s ease-in-out,color .3s ease-in-out;    

                    &:hover {
                        color: $gray-600;
                    }

                    &.active {
                        color: $gray-600;
                    }
                }
            }
        }

        .dropdown-menu-right {
            width: 100%;
            top: 60px;
            border: 0;
            border-radius: 0;
            padding: 0;
    
            .dropdown-item {
                color: $primary-color;
                font-family: $font-primary;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;

                margin: .5rem 0;

                -webkit-transition: opacity .3s ease-in-out,color .3s ease-in-out;
                -moz-transition: opacity .3s ease-in-out,color .3s ease-in-out;
                -o-transition: opacity .3s ease-in-out,color .3s ease-in-out;
                -ms-transition: opacity .3s ease-in-out,color .3s ease-in-out;
                transition: opacity .3s ease-in-out,color .3s ease-in-out;    


                &:hover {
                    color: $primary-color;
                }

                &.active,
                &:active {
                    color: $primary-color;
                    background-color: rgba(211, 158, 0, 0.1);
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    #main-header {
        .navbar {
            .navbar-nav {
                padding: 1rem 2rem;
    
                .nav-item {
                    width: fit-content;
                    margin: auto 1rem;
                    padding: 0;
                    border-bottom: none;
    
                    &:last-child {
                        border-bottom: none;
                    }
    
                    .nav-link {
                        color: $secondary-color;
                        font-family: $font-primary;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
    
                        &:hover {
                            color: $primary-color;
                        }

                        &.active {
                            color: $primary-color;
                        }
                    }
                }
            }
            
            .dropdown-menu-right {
                width: auto;
                top: 65px;
 
                .dropdown-item {
                    color: $secondary-color;
                    font-family: $font-primary;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            }
        }
    }
}