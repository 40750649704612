.home-container {
    max-width: 100%;

    .main-banner {
        width: 100%;
        margin: 0 auto;
    
        .banner {
            width: 100%;
        }
    }

    .home-brands {
        .brands-title {
            padding: 1rem 1rem 0;
            color: $text-color-1;
            font-family: $font-secondary;
            font-size: 2rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .brands-text {
            padding: 0 1rem 1rem;
            color: $text-color-2;
            font-family: $font-secondary;
        }

        .all-brands {
            display: flex;
            flex-wrap: wrap;
            
            .brand {
                padding: 1rem;
                
                .card {
                    box-shadow: none;
                    
                    .brand-logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            opacity: 1;

                            -webkit-transition: opacity .3s ease-in-out;
                            -moz-transition: opacity .3s ease-in-out;
                            -o-transition: opacity .3s ease-in-out;
                            -ms-transition: opacity .3s ease-in-out;
                            transition: opacity .3s ease-in-out;

                            &:hover {
                                opacity: .75;
                            }
                        }
                    }
                    
                    .card-title {
                        font-family: $font-secondary;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        color: $text-color-1;
                        margin-bottom: 1.8rem;
                    }
                    
                    .card-text {
                        font-family: $font-secondary;
                        color: $text-color-2;

                        white-space: pre-line;
                    }
                }
                
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .home-container {
        max-width: 80%;

        .home-brands {
            width: 80%;
            margin: 0 auto;
        }
    }
}
