.brand-details-container {
    max-width: 100%;

    .brand-banner {
        width: 100%;
        margin: 0 auto;

        .banner {
            width: 100%;
        }
    }

    .brand-details {
        // border: solid red;

        .brand-name {
            padding: 1rem 1rem 0;
            color: $text-color-1;
            font-family: $font-secondary;
            font-size: 2.5rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .brand-description {
            padding: 0 1rem 1rem;
            color: $text-color-2;
            font-family: $font-secondary;

            white-space: pre-line;
        }

        .brand-logo {
            width: 100%;
            margin: 0 auto;

            display: flex;
            justify-content: center;
            align-items: flex-start;

            .logo {
                // width: 100%;
                max-width: 300px;
            }
        }

        .brand-deck {
            li {
                list-style: none;
            }

            .brand-deck-button {
                display: flex;
                align-items: center;

                width: fit-content;
                height: initial;

                margin: 1rem auto;
                padding: 0.4rem 1.8rem;

                border: solid $primary-color;
                border-radius: 0;

                font-family: $font-primary;
                font-size: 1.4rem;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 2px;

                color: $white;
                background-color: $primary-color;

                &:hover {
                    font-weight: 600;
                }

                img {
                    margin-right: 1.4rem;
                }
            }
        }
    }

    .brand-products {
        display: flex;
        flex-wrap: wrap;

        .product {
            padding: 1rem;

            .card {
                box-shadow: none;

                .product-logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        opacity: 1;

                        -webkit-transition: opacity 0.3s ease-in-out;
                        -moz-transition: opacity 0.3s ease-in-out;
                        -o-transition: opacity 0.3s ease-in-out;
                        -ms-transition: opacity 0.3s ease-in-out;
                        transition: opacity 0.3s ease-in-out;

                        &:hover {
                            opacity: 0.75;
                        }
                    }
                }

                .card-title {
                    font-family: $font-secondary;
                    letter-spacing: 1px;

                    color: $text-color-1;
                }

                .card-text {
                    font-family: $font-secondary;
                    font-weight: 800;

                    color: $primary-color;
                }

                .product-season {
                    display: block;

                    font-family: $font-primary;
                    font-size: 0.8rem;
                    letter-spacing: 1px;

                    background-color: #ffdede;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .brand-details-container {
        max-width: 80%;
    }
}
