.login-container {
    padding: 0;

    .card {
        box-shadow: none;
    }

    .login-card-header {
        text-align: center;
        border: 0;
        border-radius: 0;
        padding: 2rem 2rem 0;
    
        .title {
            color: $primary-color;
            font-size: 36px;
            font-family: $font-primary;
            text-transform: uppercase;      
        }

        .description {
            font-size: 16px;
            font-family: $font-secondary;
        }
    }
}

@include media-breakpoint-up(sm) {
    .login-container {
        padding: 0;
        border: solid $primary-color;

        .card {
            box-shadow: 0px 2px 24px -12px rgba(0,0,0,0.3);
        }
    }
}
